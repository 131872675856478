<script setup lang="ts">
import { type SearchResults, type Game, GameType, type GameSearchFilter } from '@game/types'
import { vIntersectionObserver } from '@vueuse/components'
import { isEmpty } from 'lodash-es'
import { useAuthSync, useDevice } from '#imports'

definePageMeta({
    heroPage: 'frontpage',
    heroSkeleton: 'offer',
})

const { isLoggedIn, isExisting } = useAuthSync()
const { isHydrating } = useNuxtApp()
const { isDesktopOrTablet } = useDevice()

// SEO - START
const { data: pageData } = await usePageData('frontpage')

const { getHead, getSeoMeta } = useStrapiSeo(
    pageData.value?.seo || {
        metaTitle: 'Online Casino #1 | Nye spillere får 100% i velkomstbonus',
        metaDescription: 'Nye spillere får 100% bonus på første indbetaling. Regler & vilkår. 18+. Spil Ansvarligt ✅ Sjove spilleautomater ✅ Dansk  ✅ Høj kundetilfredshed'
    }
)

useHead(getHead())
useSeoMeta(getSeoMeta())
// SEO - END

// Hero
if (import.meta.client && !isHydrating) {
    const hero = useHero()

    await hero.setHeroData('frontpage', 'offer')
}

// Popular games
const rootMargin = '150px'
const gameConfig = useGameConfig()
const { isLiveCasino } = useGameType()
const page_size = 16

const { data: popularGames, pending: popularGamesLoading } = await useGames({
    params: { page: 1, page_size },
    key: 'slots-popular',
    lazy: true
})

// Game jackpots
const { gamesJackpots } = useGamesJackpots()

const sortedJackpotSlugs = computed(() => {
    if (isEmpty(gamesJackpots?.value)) return []

    const jackpotSlugList = Object.keys(gamesJackpots.value)
        .sort((a, b) => Number.parseFloat(gamesJackpots.value[b]?.superJackpot ?? gamesJackpots.value[b]?.jackpot) - Number.parseFloat(gamesJackpots.value[a]?.superJackpot ?? gamesJackpots.value[a]?.jackpot))
        .slice(0, page_size)

    return jackpotSlugList
})

const jackpotSectionIntersecting = ref(false)
const jackpotFilter = computed(() => {
    return {
        slugs: toValue(sortedJackpotSlugs),
        has_jackpot: true
    }
})
const { data: jackpotGames } = await useGames({
    params: { page: 1, page_size },
    filter: jackpotFilter,
    lazy: true,
    key: () => 'slots-jackpot',
    enabled: () => !!toValue(sortedJackpotSlugs)?.length && toValue(jackpotSectionIntersecting)
})

function loadJackpotGames([{ isIntersecting }]: IntersectionObserverEntry[]) {
    if (!isIntersecting) return
    if (jackpotGames?.value?.data?.length) return

    jackpotSectionIntersecting.value = true
}

const sortedJackpotGames = computed(() => {
    if (!jackpotGames?.value?.data?.length) return []

    const sorted = jackpotGames.value.data.slice().sort((a: Game, b: Game) => {
        const idxA = sortedJackpotSlugs.value.indexOf(a.slug)
        const idxB = sortedJackpotSlugs.value.indexOf(b.slug)

        return idxA - idxB
    })

    return sorted
})

// Other games
const otherSectionIntersecting = ref(false)
const { data: otherGames } = await useGames({
    params: { page: 1, page_size: page_size },
    filter: {
        game_types: [GameType.SLOTS],
        has_jackpot: false
    },
    lazy: true,
    key: () => 'slots-other',
    enabled: () => otherSectionIntersecting.value
})

function loadOtherGames([{ isIntersecting }]: IntersectionObserverEntry[]) {
    if (!isIntersecting) return

    otherSectionIntersecting.value = true
}

// Live casino games
const liveCasinoSectionIntersecting = ref(false)
const { data: liveCasinoGames } = await useGames({
    params: { page: 1, page_size: page_size },
    filter: {
        game_types: [GameType.CASINO]
    },
    lazy: true,
    key: () => 'live-casino',
    enabled: () => liveCasinoSectionIntersecting.value
})

const sortedLiveCasinoGames = computed<Game[]>(() => {
    if (!liveCasinoGames.value?.data?.length) return []

    const liveCasino = liveCasinoGames.value.data.filter((x: Game) => isLiveCasino(x.game_type, x.game_subtype))

    liveCasino.sort((a: Game, b: Game) => {
        if (a.opening_hours.is_open === b.opening_hours.is_open) return 0

        if (a.opening_hours.is_open) return -1

        return 1
    })

    return liveCasino
})

function loadLiveCasinoGames([{ isIntersecting }]: IntersectionObserverEntry[]) {
    if (!isIntersecting) return

    liveCasinoSectionIntersecting.value = true
}

// Game Search
const gameThemes = {
    // Tag på eventyr i det vilde vesten med cowboys og guldgraver-spil:
    western: ['diamond-express', 'the-gold-mine', 'gold-rush', 'six-shooter', 'railroad-express'],
    // Oplev hjertet af byens pulserende liv:
    cityLife: ['airport-machine', 'the-mafia', 'bettys-burgers', 'inspector', 'fire-fighters', 'city-builder', 'private-detectives', 'who-dun-it', 'cafe-spionage', 'lucky-moles', 'jewel-heist', 'auction-day', 'mystery-hotel', 'bank-walt'],
    // Spændende spilleautomater, der inviterer til magi og eventyr:
    adventure: ['wizards-workshop', 'dragon-slayer', 'magic-jungle', 'luckys-factory', 'mini-yeti', 'haunted-house', 'candy-kingdom', 'jewel-mountain', 'gods-of-olympus', 'the-vikings', 'pirate-tales'],
    // Udforsk spil, der tager dig til fjerne verdener og mystiske lande:
    travel: ['jewels-of-egypt', 'tomb-of-treasures', 'golden-pyramid', 'amazonian-treasures', 'magic-jungle', 'tropical-paradise', 'outback-safari', 'lost-city', 'heidi-hunt', 'safari', 'the-raft', 'temple-of-fortune', 'the-bazaar', 'sushi-time', 'caveman', 'le-chef']
}

const searchFilters: GameSearchFilter[] = [
    // { name: 'Nye spil', value: 'new', type: 'category', icon: 'rocket', filter: (gameList: Game[]) => gameList.filter((x: Game) => x.is_new) },
    // { name: 'Spil med Jackpot', value: 'jackpot', type: 'category',icon: 'jackpot-star', filter: (gameList: Game[]) => gameList.filter(x => x.has_jackpot).sort((a: Game, b: Game) => Number(b.superJackpot) - Number(a.superJackpot)) },
    { name: 'Det Vilde Vesten', value: 'western', type: 'category', icon: 'western', filter: { slugs: gameThemes.western } },
    { name: 'Bylivet', value: 'cityLife', type: 'category', icon: 'city-life', filter: { slugs: gameThemes.cityLife } },
    { name: 'Eventyrlige rejser', value: 'travel', type: 'category', icon: 'tropical', filter: { slugs: gameThemes.travel } },
    { name: 'Magiske øjeblikke', value: 'adventure', type: 'category', icon: 'adventure', filter: { slugs: gameThemes.adventure } },
]

const searching = ref<boolean>(false)
const searchPhrase = ref<string | null>('')
const activeFilter = ref<string | null>(null)
const searchResults = ref<SearchResults | null>()

const isSearchEmpty = computed(() => {
    return !searchResults?.value?.matches.length && !searchResults.value?.suggestions.length
})

const activeFilterName = computed(() => {
    const filter = searchFilters.find(searchFilter => searchFilter.value === activeFilter.value)

    return filter?.name
})

function handleSearch(payload: { type: 'loading' | 'results' | 'filter' | 'phrase', value: SearchResults | boolean | string | null }) {
    if (payload.type === 'loading') return searching.value = payload.value as boolean
    if (payload.type === 'results') return searchResults.value = payload.value ? payload.value as SearchResults : null
    if (payload.type === 'filter') {
        searchPhrase.value = null

        return activeFilter.value = payload.value ? payload.value as string : null
    }
    if (payload.type === 'phrase') return searchPhrase.value = payload.value as string || null
}
</script>

<template>
    <div class="frontpage">
        <div class="games-with-sidebar">
            <div class="games-with-sidebar__games">
                <!-- SAVED GAMES -->
                <LazyGameSaved
                    v-if="isLoggedIn"
                    :has-sidebar="true"
                />
                <!-- SAVED GAMES END -->

                <!-- POPULAR GAMES -->
                <div class="games-list__skeletons">
                    <GameSkeletonList
                        :title="gameConfig.translations.gameList.POPULAR"
                        :amount="!isDesktopOrTablet ? 9 : 12"
                        :has-side-bar="true"
                        :loading="popularGamesLoading || !popularGames?.data?.length"
                        :auto-height="{
                            offset: 150
                        }"
                    >
                        <GameList
                            :games="popularGames.data"
                            :title="gameConfig.translations.gameList.POPULAR"
                            category="POPULAR"
                            :video-enabled="true"
                            :has-sidebar="true"
                            :show-all-button="false"
                            :limit-list="true"
                        />
                    </GameSkeletonList>
                </div>
                <!-- POPULAR GAMES END -->
            </div>

            <!-- WINNERS SIDEBAR -->
            <div class="games-with-sidebar__winners">
                <LazyWinnersSidebarContainer v-if="isDesktopOrTablet" />
            </div>
            <!-- WINNERS SIDEBAR END -->
        </div>

        <!-- GAME SEARCH -->
        <div class="search-bar">
            <GameSearch
                :show-search="true"
                :filters="searchFilters"
                @game-search="handleSearch"
            />
        </div>

        <div class="search-results__skeletons">
            <GameSkeletonList
                :amount="10"
                :loading="searching"
            >
                <template v-if="(searchPhrase || activeFilter) && searchResults && searchResults.success && searchResults.matches.length && !isSearchEmpty">
                    <GameList
                        :key="activeFilterName"
                        category="matches"
                        :games="searchResults.matches"
                        :title="searchPhrase ? 'Søgeresultater' : (activeFilterName || 'matches')"
                        :video-enabled="true"
                    />
                </template>
            </GameSkeletonList>
            <div
                v-if="(searchPhrase || activeFilter) && searchResults && !searchResults.success && isSearchEmpty"
                class="search-results__no-results"
                style="text-align: center; font-size: 16px; margin: 20px 0;"
            >
                <p>Vi fandt desværre ingen søgeresultater for <i>{{ searchPhrase || activeFilterName }}</i>.</p>
            </div>
        </div>

        <div v-show="!searching && !searchResults">
            <!-- JACKPOT GAMES -->
            <GameSkeletonList
                v-intersection-observer="[loadJackpotGames, { rootMargin }]"
                class="games-list__skeletons"
                :title="gameConfig.translations.gameList.JACKPOT"
                :amount="page_size"
                :loading="!sortedJackpotGames?.length"
            >
                <GameList
                    :games="sortedJackpotGames"
                    :limit-list="true"
                    :show-all-link="{ href: '/spilleautomater' }"
                    :show-all-button="true"
                    :title="gameConfig.translations.gameList.JACKPOT"
                    category="JACKPOT"
                    :video-enabled="true"
                />
            </GameSkeletonList>

            <!-- JACKPOT GAMES END -->

            <!-- OTHER GAMES -->
            <GameSkeletonList
                v-intersection-observer="[loadOtherGames, { rootMargin }]"
                class="games-list__skeletons"
                :title="gameConfig.translations.gameList.OTHER"
                :amount="page_size"
                :loading="!otherGames?.data?.length"
            >
                <GameList
                    :games="otherGames.data"
                    :limit-list="true"
                    :show-all-link="{ href: '/spilleautomater' }"
                    :show-all-button="true"
                    :title="gameConfig.translations.gameList.OTHER"
                    category="OTHER"
                    :video-enabled="true"
                />
            </GameSkeletonList>
            <!-- OTHER GAMES END -->

            <!-- CASINO LIVE GAMES -->
            <GameSkeletonList
                v-intersection-observer="[loadLiveCasinoGames, { rootMargin }]"
                class="games-list__skeletons"
                :title="gameConfig.translations.gameList.LIVE_CASINO"
                :amount="page_size"
                :loading="!sortedLiveCasinoGames?.length"
            >
                <GameList
                    :games="sortedLiveCasinoGames"
                    :limit-list="true"
                    :show-all-link="{ href: '/casino' }"
                    :show-all-button="true"
                    title="LIVE_CASINO"
                    category="LIVE_CASINO"
                    :video-enabled="true"
                />
            </GameSkeletonList>
            <!-- CASINO LIVE GAMES END -->
        </div>
        <!-- GAME SEARCH END -->

        <LazyStrapiDynamicRenderer
            v-if="!isExisting && !isEmpty(pageData?.dynamicContent)"
            :content="pageData?.dynamicContent"
            :max-width="1024"
        />
    </div>
</template>

<style lang="scss" scoped>
.games-with-sidebar {
    display: flex;

    > div {
        min-width: 226px;
    }

    &__games {
        flex: 1;

        @media (min-width: $screen-lg) {
            padding-right: 20px;
        }
    }

    &__winners {
        display: none;

        @media (min-width: $screen-lg) {
            display: flex;
            flex-direction: column;
            width: calc((100% - 60px) / 4); // 3 tiles & 1 winner sidebar with 20px gap/padding each
            margin-top: 53px; // Align with tiles
        }

        @media (min-width: $screen-xl) {
            width: calc((100% - 80px) / 5); // 4 tiles & 1 winner sidebar with 20px gap/padding each
        }
    }
}
</style>
